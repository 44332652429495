import { COLORS, IMAGES, FONTS} from "../../../../constants/index";
import { StyleSheet, View, Text, Dimensions, TouchableOpacity, Image, Alert, Modal, ScrollView } from "react-native";
import React, { useState } from "react";
import { X } from 'lucide-react';
import Button from "../../../../components/Button";
import { useCart } from "../../../../context/SinglecartContext";
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from 'react-native-responsive-screen';
import { useGroupCart } from "../../../../context/GroupmanagerContext";
import { useNavigation } from '@react-navigation/native';

let screenWidth;
  // Responsive styling logic
let myheight, mywidth;

function Offers({ data, groupno, memberId }) {
  const navigation = useNavigation();
  const { dispatch, getQuantityOfItem, stateLayout,merchantData } = useCart();
  const {addToCart, removeFromCart, state} = useGroupCart();
  
  const [offermodal, setOffermodal] = useState(false)

  const openModalFilter = () => {
    setOffermodal(true)
  }
  const closeTheModal = () => {
    setOffermodal(false)
  }

  screenWidth=Dimensions.get('window').width;

  //console.log('screenwidth equal or great than',screenWidth)
  if(screenWidth >= 1300) {
    myheight = hp(6);
    mywidth = hp(6);
  }
  else if (screenWidth >= 1200) {
    myheight = hp(9);
    mywidth = hp(9);
  } else if (screenWidth >= 992) {
    myheight = hp(6);
    mywidth = hp(6);
  }
  else if (screenWidth >= 850) {
    // Large Screens
    myheight = hp(6);
    mywidth = hp(6);
  }
   else if (screenWidth >= 768) {
    // Medium Screens
    myheight = hp(8);
    mywidth = hp(8);
  } else if (screenWidth >= 576) {
    // Small Screens
    myheight = hp(10);
    mywidth = hp(10);
  } else {
    // Extra Small Screens
    myheight = hp(10);
    mywidth = hp(10);
  }


 // console.log('my height',myheight,' width ',mywidth);

  const bringToTable = async (product) => { 
    const finalProduct = { ...product, orderType: "table" }; 
    if(groupno && memberId ) {
      addToCart(groupno, memberId, finalProduct)
    }else{
      dispatch({ type: 'ADD_TO_CART', payload: finalProduct });
    }
  };
  const addItemToCart = (product) => {
    console.log('added item ',product);
    if(groupno && memberId ) {description
      addToCart(groupno, memberId, product);
    }else{
      dispatch({ type: 'ADD_TO_CART', payload: product })
    }
  }
  const generalRemoveFromCart = (product) => {
    if(groupno && memberId ) {
      removeFromCart(groupno, memberId, product)
    }else{
      dispatch({ type: 'REMOVE_FROM_CART', payload: product });
    }
  }

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + '...';
    }
    return text;
  };

 
  // const navigaatetocart = () => {
  //   navigation.navigate("CartScreen", {getQuantityOfItem, merchantData }); // Ensure HomeScreen is correctly set up in your navigator
  //   setOffermodal(false);
  // };

  // const hangleGoToCartPage = () => {
  //   navigation.navigate("CartScreen", { getQuantityOfItem, merchantData });
  //   setOffermodal(false);
  // };

  const hangleGoToCartPage = () => {
    console.log('Offers');
    navigation.navigate("CartScreen", { getQuantityOfItem, merchantData });
    setOffermodal(false);
  };
 

  return (
    <>
     <TouchableOpacity style={styles.singleOfferView} onPress={openModalFilter}>
        <View style={{flexDirection: "row", alignItems: "center"}}>
            <View style={[styles.singleOfferImg, {backgroundColor: "white"},{ height: myheight, width: mywidth }]}>
           
            {/* <View style={[styles.singleOfferImg, { height: myheight, width: mywidth }]}> */}
            <Image source={data.image} style={[styles.singleOfferTagImg, { height: myheight, width: mywidth }]}/>
            </View>
            <View style={styles.offertextsDesc}>
            <View style={{flexDirection: "row", alignItems: "center"}}> 
                <Text style={[styles.amountOffer, {fontSize: 16}]}>{data.name}</Text>
            </View>
            <View style={{flexDirection: "column"}}>
            <Text style={[ {color: COLORS.grayText, fontSize: 12}]}>{truncateText(data.description,20)}</Text>
                 <View style={{flexDirection: "row", alignItems: "center"}}>
                 <Text style={[styles.amountOffer, {fontWeight: 700, color: "#11A20B" }]}>KSH {data.price - Math.floor((data.discount_percentage / 100) * data.price)}</Text>
                <Text style={[styles.amountOffer, {fontWeight: 700, textDecorationLine: 'line-through', fontSize: 10,}]}>KSH {data.price}</Text>
                
                 </View>
            </View>
            </View>
        </View>
        <View style={styles.handleChnageCart}>
            {/* <Text style={{marginLeft: 10, color: "skyblue", fontSize: 12, marginBottom: 15}}>Save Ksh. {Math.floor((data.discount_percentage / 100) * data.price)}</Text> */}
             <View style={{flexDirection: "row", alignItems: "center"}}>
                <TouchableOpacity style={styles.cartBtnsOutline} onPress={() => generalRemoveFromCart(data)}><Text style={styles.actions}>-</Text></TouchableOpacity>
                <Text style={styles.cartDisplayCapacity}>{getQuantityOfItem(data.id)}</Text>
                <TouchableOpacity style={styles.cartBtnsOutline} onPress={() => addItemToCart(data)}><Text style={styles.actions}>+</Text></TouchableOpacity>
            </View>
        </View> 
     </TouchableOpacity>

    {/* Modal */}
     <Modal
        animationType="slide"
        visible={offermodal}
        transparent={true}
     >
        <View style={[styles.modalContainer, {alignSelf: stateLayout}]}>
            <View style={styles.modalContent}>
                <TouchableOpacity style={styles.madalPayAmount}>
                    <View style={styles.barTopSectionView}></View>
                </TouchableOpacity>
                <TouchableOpacity style={styles.topCloseModal} onPress={closeTheModal}><X color="white" size={18}/></TouchableOpacity>
                {/* Modal content */}
                <View style={styles.windowDisplay}>
                    <View style={styles.imageModalContainer}>
                         <Image source={data.image} style={styles.singleOfferTagImgModal} resizeMode="stretch"/>
                    </View>
                    <ScrollView style={{flex: 1}}
                    showsVerticalScrollIndicator={false}
                    showsHorizontalScrollIndicator={false}
                    >
                        <View style={styles.textContainer}>
                            <Text style={[styles.textMain, {fontWeight: 700, paddingVertical: 5}]}>{data.name}</Text>
                        
                            
                            <Text style={styles.textMain}>{data.description} </Text>
                                <View style={{flexDirection: "row", alignItems: "center"}}>
                                <Text style={[styles.amountOffer, {fontWeight: 700, textDecorationLine: 'line-through'}]}>Ksh. {data.price}</Text>
                                    <Text style={[styles.amountOffer, {fontWeight: 700, color: "#11A20B", marginLeft: 5}]}>Ksh. {data.price - Math.floor((data.discount_percentage / 100)* data.price)}</Text>
                                    {/* <Text style={{marginLeft: 7, color: "skyblue", fontSize: 10}}>Save Ksh. {Math.floor((data.discount_percentage / 100)* data.price)}</Text> */}
                                </View>
                       
                        </View>
                        <View style={styles.modalActionButton}>
                            <View style={{flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
                                <TouchableOpacity style={styles.cartBtnsOutline} onPress={() => generalRemoveFromCart(data)}>
                                    <Text style={styles.actions}>-</Text>
                                </TouchableOpacity>
                                <Text style={styles.cartDisplayCapacity}>{getQuantityOfItem(data.id)}</Text>
                                <TouchableOpacity style={styles.cartBtnsOutline} onPress={() => addItemToCart(data)}>
                                    <Text style={styles.actions}>+</Text>
                                </TouchableOpacity>
                            </View>
                        </View>
                        {/* <View style={styles.modalButtonOrder}>
                            <Button text="Bring to Table" style={styles.button} onPress={() => bringToTable(data)}/>
                        </View> */}
                          <View style={styles.modalButtonOrder}>

                          {/* {getQuantityOfItem(data.id) && getQuantityOfItem(data.id) > 1 &&(
                            // <Button
                            // text="View Cart"
                            // style={styles.button}
                            // onPress={navigaatetocart} // Call the function correctly
                            
                            // />
                            <h1>button</h1>
                          )}  */}

                      {getQuantityOfItem(data.id) > 0 && (
                            <Button
                            text="View Cart"
                            style={styles.button}
                            onPress={hangleGoToCartPage} // Call the function correctly
                            />
                      )}

                      </View>
                    </ScrollView>
                </View>
            </View>
        </View>
     </Modal>
    </>
  );
}

const styles = StyleSheet.create({
  singleOfferView: {
    marginTop: 2,
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    backgroundColor: "#343643",
    borderRadius: 10,
    height: 83,
    width: "100%",
    paddingHorizontal: 5,
    marginBottom: 10,
  },
  singleOfferImg: {
    // should apply here

    objectFit: 'contain',
    // width: 70,
   // aspectRatio:1,
    borderRadius: 10,
    overflow: "hidden",
  },
  singleOfferTagImg: {
    width: "100%",
    height: "100%",
    borderRadius: 10,
    // objectFit: "cover",
    // maxWidth: "100px",  // Add a reasonable maximum width for large screens
    // maxHeight: "100px", // Add a reasonable maximum height for large screens
    // overflow: "hidden", // Prevent content overflow
  },
  offertextsDesc: {
    marginLeft: 15,
    marginLeft:15,
    justifyContent:"space-between",
  },
  amountOffer: {
    ...FONTS.fineText,
    fontSize: 14,
    paddingVertical: 3,
    marginRight: 20,
  },
  handleChnageCart: {
    justifyContent: "center",
    alignItems: "center",
    marginRight: 5,
  },
  cartBtnsOutline: {
    width: 20,
    height: 20,
    textAlign: "center",
    borderRadius: 100,
    borderWidth: 1,
    borderColor: COLORS.white,
    justifyContent: "center",
    alignItems: "center",
  },
  actions: {
    ...FONTS.bigsubtext,
    fontSize: 11,
  },
  cartDisplayCapacity: {
    marginHorizontal: 10,
    ...FONTS.fineText,
  },
  
  // Modal styles
  modalContainer: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    paddingTop: 5,
    width: wp(100),
    height: hp(100),
    maxWidth: 430,
  },
  modalContent: {
    backgroundColor: COLORS.backgroundDark,
    padding: 20,
    width: '100%', 
    height: hp(97),
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    elevation: 5,
  },
  madalPayAmount: {
    width: "100%",
    height: 5,
    justifyContent: "center",
    alignItems: "center",
  },
  modelTextColor: {
    paddingVertical: 5,
    width: "100%",
    textAlign: "center",
    ...FONTS.bigsubtext,
  },
  barTopSectionView: {
    borderTopWidth: 3,
    height: 1,
    borderTopColor: "white",
    width: 52,
    borderRadius: 100,
    marginBottom: 5,
    marginTop: -20,
  },
  topCloseModal: {
    width: "100%",
    paddingHorizontal: 5,
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: 'row',
    backgroundColor:COLORS.grey,
    width:30,
    borderRadius:10,
  },
  button: {
    backgroundColor: COLORS.blue,
    borderWidth: 1,
    borderColor: COLORS.blue,
    marginVertical: 10,
    width: "90%",
  },
  windowDisplay: {
    width: "100%",
    flex: 1,
    //paddingVertical: 5,
  },
  // imageModalContainer: {
  //   width: "100%",
  //   height: 310,
  //   borderRadius: 10,
  //   overflow: "hidden",
  //   backgroundColor: "white",
  //   marginVertical: 5,
  //   justifyContent: "center",
  //   alignItems: "center",
  // },
  imageModalContainer: {
    width: "100%",
    height: 310,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    overflow: "hidden",
    backgroundColor: "white",
    justifyContent: "center",
    alignItems: "center",
    marginVertical: 5,
  },
  singleOfferTagImgModal: {
    width: "100%",
    height: "100%",
  },
  textContainer: {
    width: "100%",
    paddingVertical: 5,
    paddingHorizontal: 10,
    backgroundColor: COLORS.backgroundLight,
    marginVertical: 10,
    borderRadius: 10,
  },
  textMain: {
    ...FONTS.smallfineText,
    fontSize: 14,
  },
  modalActionButton: {
    marginVertical: 10,
  },
  modalButtonOrder: {
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    marginTop: -10,
  }
});
export default Offers;
