import React from 'react';
import { FlatList, Text, View, StyleSheet, ActivityIndicator } from 'react-native';
import Singleoffer from '../utilities/Singleoffer'; // Update the path as needed
import Fullfood from '../utilities/Fullfood'; // Update the path as needed
import Offers from '../utilities/Offers'; // Update the path as needed
//import { FONTS } from '../../../../constants/index'; // Ensure the correct path
import { COLORS, IMAGES, FONTS } from "../../../../constants/index";
const ExportedRenderItems = ({ filteredItems, products, onEndReached, onEndReachedThreshold, ListFooterComponent, groupno, memberId }) => {
  
  const groupBySubgroup = (items) => {
    return items.reduce((acc, item) => {
      const subgroup = item.subcategory_name || 'Other';
      if (!acc[subgroup]) {
        acc[subgroup] = [];
      }
      acc[subgroup].push(item);
      return acc;
    }, {});
  };

  // Use filteredItems if available, otherwise use products
  const itemsToRender = filteredItems && filteredItems.length > 0 ? filteredItems : products;
  const groupedItems = groupBySubgroup(itemsToRender);

  // Create an array of items including subgroup headers
  const groupedData = Object.entries(groupedItems).reduce((acc, [subgroup, items]) => {
    acc.push({ type: 'header', subgroup });
    items.forEach(item => acc.push({ ...item, type: 'item' }));
    return acc;
  }, []);

  const renderItem = ({ item }) => {
    if (item.type === 'header') {
      return <Text style={styles.subgroupHeader}>{item.subgroup}</Text>;
    } else if (item.type === 'item') {
      if (item.offer_price > 0) {
        //console.log('filtered all items ',item)
        return <Offers key={item.id} data={item} groupno={groupno} memberId={memberId} />;
      } else if (item.category_name === 'Food') {
       // console.log('filtered food items ',item)
        return <Fullfood key={item.id} data={item} groupno={groupno} memberId={memberId} />;
      } else {
       // console.log('filtered items ',item)
        return <Singleoffer key={item.id} data={item} groupno={groupno} memberId={memberId} />;
      }
    }
    return null;
  };

  return (
    <FlatList
      data={groupedData}
      renderItem={renderItem}
      filteredItems={filteredItems}
      keyExtractor={(item, index) => item.type === 'header' ? `header-${item.subgroup}` : item.id.toString()}
      onEndReached={onEndReached}
      onEndReachedThreshold={onEndReachedThreshold}
      ListFooterComponent={ListFooterComponent}
    />
  );
};

const styles = StyleSheet.create({
  subgroupHeader: {
    ...FONTS.fineText,
    fontSize: 14,
    paddingVertical: 3,
    marginBottom: 10,
    //backgroundColor: '#f0f0f0', // optional: to visually distinguish headers
    paddingLeft: 10,
  },
});

export default ExportedRenderItems;
