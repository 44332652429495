import React, { useState, useEffect } from "react";
import { StyleSheet, View, Dimensions, TextInput, TouchableOpacity, ActivityIndicator } from "react-native";
import { COLORS } from "../constants";
import { Search } from "lucide-react";

const SearchBar = ({ searchTerm, onChangeText, onSearch, isSearching, isTyping }) => {
  const [typingTimeout, setTypingTimeout] = useState(null);

  // Debounced search
  const handleInputChange = (text) => {
    // Clear the previous timeout to prevent premature search
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    // Update the search query immediately
    onChangeText(text);

    // Set a new timeout
    const timeout = setTimeout(() => {
      // Trigger search after the user has stopped typing
      onSearch();
    }, 500); // Adjust debounce delay as needed (500ms in this case)

    setTypingTimeout(timeout);
  };

  return (
    <View style={[styles.inputwrapper, isTyping && styles.activeInputWrapper]}>
      <View style={[styles.QueryButton, isTyping && styles.activeInputWrapper]}>
        {isSearching ? (
          <ActivityIndicator size="small" color={COLORS.blue} style={styles.spinner} />
        ) : (
          <TouchableOpacity onPress={onSearch} style={styles.searchButton}>
            <Search size={20} color={COLORS.white} />
          </TouchableOpacity>
        )}
      </View>
      <TextInput
        placeholder="Search"
        placeholderTextColor={COLORS.white}
        value={searchTerm}
        onChangeText={handleInputChange} // Use the debounced input handler
        style={styles.input}
      />
    </View>
  );
};

export default SearchBar;

const { width, height } = Dimensions.get("screen");
const styles = StyleSheet.create({
  inputwrapper: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: COLORS.backgroundLight,
    borderRadius: 10,
    paddingHorizontal: 5,
    paddingVertical: 3, // Reduced paddingVertical
    width: "98%",
  },
  input: {
    height: (40 * height) / 930, // Reduced height
    paddingHorizontal: 10,
    paddingVertical: 5, // Reduced paddingVertical
    color: COLORS.white,
    marginLeft: 10,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    borderColor: "none",
    flex: 1,
  },
});
