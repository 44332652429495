import { COLORS, FONTS } from "../../../constants/index";
import { StyleSheet, View, Text, Dimensions,ActivityIndicator } from "react-native";
import React, { useEffect, useState } from "react";
import { CheckCircle2, AlertCircle } from 'lucide-react';
import Layouts from "../../Layouts/ScreenView";
import Button from "../../../components/Button";
import { useCart } from "../../../context/SinglecartContext";
import { useGroupCart } from "../../../context/GroupmanagerContext";
import { RiWhatsappFill } from 'react-icons/ri'; // Remix Icons
import { FaWhatsapp } from 'react-icons/fa'; 



function Paymentresponse({ navigation, route }) {
  const { groupno, memberId, merchantData, totalAmountPaid, brandname, billStatus, paymentOptionsPlan } = route.params || {};
  const { dispatch } = useCart();
  const { clearCart } = useGroupCart();
  const storedCheckoutData = JSON.parse(localStorage.getItem('checkoutData'));

  const [message, setMessage] = useState('');
  const [amount, setAmount]=useState('');
  const [messageColor, setMessageColor] = useState('');
  const [insystemMessage, setInsystemMessage] = useState('');
  const [paymentResponse, setPaymentResponse] = useState(null); // state to hold payment response
  
  const [orderId, setOrderId] = useState(null);
  const [orderStatus, setStatus] = useState(null);
  useEffect(() => {

    // response
    const storedPaymentResponse = JSON.parse(localStorage.getItem('paymentResponse'));
    setPaymentResponse(storedPaymentResponse);

    const currentURL = window.location.href;
   
   // console.log("final response ",storedPaymentResponse);
    const orderId = storedPaymentResponse.order_number;
    const thisStatus=storedPaymentResponse.status;

    setStatus(thisStatus);
    setOrderId(orderId);

   // console.log("status  ",storedPaymentResponse.status);
    // Extract the response parameter from the URL
    const urlParams = new URLSearchParams(currentURL.split("?")[1]);
    const encodedResponse = urlParams.get("response");

    if (encodedResponse) {

      // URL decode the response parameter
      const decodedURIComponent = decodeURIComponent(encodedResponse);
      try {
        // Base64 decode the decoded URL component
        const decodedString = atob(decodedURIComponent);
        // Parse JSON data
        const jsonData = JSON.parse(decodedString);
  
        // Check for success or error messages
        if (jsonData.resultCode === "0") {
          setMessage(jsonData.resultMessage);
          setMessageColor('green');
          setAmount(jsonData.transactionAmount);
          setInsystemMessage('You have successfully placed an order.');
        } else if (jsonData.errors && jsonData.errors.length > 0) {
          setMessage(jsonData.errors[0].message);
          setMessageColor('orange');
          setInsystemMessage('Payment wasn\'t successful, please try again.');
        }
      } catch (error) {
        console.error('Error decoding or parsing the response:', error);
      }
    } else {
      setMessage('No response found');
      setMessageColor('red');
      setInsystemMessage('Payment wasn\'t successful, please try again.');
    }
  }, []);


  const generalClearCart = () => {
    if (groupno && memberId) {
      clearCart(groupno);
    } else {
      dispatch({ type: 'CLEAR_CART', payload: groupno });
    }
  };


  const navigateToOffers = () => {
    generalClearCart();
    
    // Get the current URL and remove the &response parameter
    let currentURL = window.location.href;
    const url = new URL(currentURL);
    url.searchParams.delete('response');
    const updatedURL = url.toString();

    localStorage.getItem('paymentResponse')
    localStorage.removeItem("cartItems"); 

    window.history.replaceState(null, '', updatedURL);
    navigation.navigate("HomeScreen");
  };

  const handleBillViewAMount = () => {
    navigation.navigate("RecieptScreen", { showPersonaStatus: true });
  };



  return (
    <Layouts>
      <View style={styles.container}>
        <View style={styles.topStatus}>
          {paymentResponse && orderStatus === '200' ||orderStatus === 200 ? (
            <>
              <CheckCircle2 size={70} color="green" />
              <Text style={{ ...FONTS.smallfineText, fontSize: 14, paddingVertical: 10, textAlign: "center", lineHeight: 20 }}>
                {paymentResponse.message}
              </Text>
              <Text style={{ ...FONTS.smallfineText, fontSize: 14, paddingVertical: 10, textAlign: "center", lineHeight: 20, color: COLORS.blue }}>
                 #{orderId}
              </Text>
              <RiWhatsappFill size={18} color="green"/>
            </>
          ) : (
            <>
              <AlertCircle size={70} color="orange" />
              <Text style={{ ...FONTS.smallfineText, fontSize: 14, paddingVertical: 10, textAlign: "center", lineHeight: 20 }}>
                {paymentResponse?.message || 'An error occurred while processing your payment.'}
              </Text>
              {/* <Text style={{ ...FONTS.smallfineText, fontSize: 14, paddingVertical: 10, textAlign: "center", lineHeight: 20, color: COLORS.blue }}>
                Order Number: #{paymentResponse.order_id
                }
              </Text> */}
            </>
          )}
        </View>

        <View style={styles.footerSection}>
        {paymentResponse && paymentResponse.status === "success" ? (
        <>
         
         <Button text="Home" onPress={navigateToOffers} style={styles.buttonFooter} />
            {/* <Text style={{ ...FONTS.smallfineText, color: "skyblue", fontSize: 14, paddingVertical: 10 }}>Download receipt</Text> */}
       
        </>
        
        ):(
        <>
       
         <Button text="Home" onPress={navigateToOffers} style={styles.buttonFooter} />
            {/* <Text style={{ ...FONTS.smallfineText, color: "skyblue", fontSize: 14, paddingVertical: 10 }}>Download receipt</Text> */}
          
          </>
        )}
        </View>
      </View>
    </Layouts>
  );
}

const { width, height } = Dimensions.get("screen");
const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: COLORS.backgroundDark,
    position: "relative",
    maxWidth: 430,
  },
  topStatus: {
    justifyContent: "space-around",
    alignItems: "center",
    position: "absolute",
    top: 200,
    width: "60%",
  },
  footerSection: {
    width: "100%",
    position: "absolute",
    bottom: 40,
    right: 0,
    left: 0,
    justifyContent: "center",
    alignItems: "center",
  },
  buttonFooter: {
    width: "30%",
  },
  footerSections: {
    width: "100%",
    position: "absolute",
    bottom: 40,
    right: 0,
    left: 0,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
  buttonFooters: {
    width: "100%",
    marginRight: 5,
    fontSize: 11,
  }
});

export default Paymentresponse;
